import { useContext, useEffect, useState } from 'react';
import { UserTraits } from '@segment/analytics-next';

import { User } from 'app/shared/typings';
import { AuthContext } from 'app/shared/context/Auth';

function setSegmentTimestamp() {
  localStorage.setItem('ajs_timestamp', new Date().getTime().toString());
}

function segmentResetIfTimestampExpired(segmentReset: () => void) {
  const ajs_timestamp = localStorage.getItem('ajs_timestamp');

  if (ajs_timestamp === null) {
    return;
  }

  const timestamp = new Date(Number(ajs_timestamp)).getTime();
  const now = new Date().getTime();
  const daysSinceTimestamp = (now - timestamp) / 86400000; // 1000 * 60 * 60 * 24

  if (daysSinceTimestamp > 90) {
    segmentReset();
  }
}

export const useFireSegmentIdentify = (
  segmentIdentify: (
    user?: User | null | undefined,
    identifyTraits?: UserTraits
  ) => void,
  segmentReset: () => void
) => {
  const { user, visitorType, loggedIn, loading } = useContext(AuthContext);
  const [
    nonLoggedInSegmentIdentifyFired,
    setNonLoggedInSegmentIdentifyFired,
  ] = useState(false);
  const [loginSegmentIdentifyFired, setLoginSegmentIdentifyFired] = useState(
    false
  );

  useEffect(() => {
    if (!loading) {
      segmentResetIfTimestampExpired(segmentReset);
      if (!loggedIn && !nonLoggedInSegmentIdentifyFired) {
        // Fire Segment Identify on app start for logged out users
        segmentIdentify();
        setSegmentTimestamp();
        setNonLoggedInSegmentIdentifyFired(true);
      } else if (loggedIn && user && !loginSegmentIdentifyFired) {
        // Fire Segment Identify on app start and login for logged in users
        segmentIdentify(user, { visitor_type: visitorType });
        setSegmentTimestamp();
        setLoginSegmentIdentifyFired(true);
      }
      // reset loginSegmentIdentifyFired after log out
      if (!loggedIn) {
        setLoginSegmentIdentifyFired(false);
      }
    }
  }, [
    segmentIdentify,
    user,
    visitorType,
    loggedIn,
    loading,
    nonLoggedInSegmentIdentifyFired,
    setNonLoggedInSegmentIdentifyFired,
    loginSegmentIdentifyFired,
    setLoginSegmentIdentifyFired,
  ]);
};
