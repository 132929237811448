import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'app/shared/context/Auth';
import ExternalRedirect from 'app/shared/components/atoms/ExternalRedirect';

const isArtistDashboardUrl = (returnTo: any) => {
  const artistDashboardUrls = [
    '/artist/dashboard',
    '/artist/edit-profile',
    '/artist/shows',
    '/artist/fans',
    '/artist/network',
    '/artist/resources',
  ];
  return artistDashboardUrls.includes(returnTo);
};

const withAuthCheck = (
  PassedComponent: React.ComponentType<any>,
  returnTo?: string
) => {
  const WrappedComponent: React.FC<any> = props => {
    const { loggedIn, user, loading } = useContext(AuthContext);
    const location = useLocation();

    if (loading) {
      return <div data-qaid="loading-session"></div>;
    }

    if (!loggedIn) {
      const redirectPath = returnTo
        ? `/signin?return_to=${encodeURIComponent(returnTo)}`
        : location.pathname
        ? `/signin?return_to=${location.pathname}`
        : '/signin';
      return <ExternalRedirect to={redirectPath} />;
    } else if (user && !user.isArtist && isArtistDashboardUrl(returnTo)) {
      return <ExternalRedirect to="/about/artists/request-artist" />;
    }
    return <PassedComponent {...props} />;
  };

  return WrappedComponent;
};

export default withAuthCheck;
