import React, { createContext, useContext, useMemo, useState } from 'react';

import { City } from 'app/shared/typings';

interface CityContextValues {
  setLastViewedCity: (city: City) => void;
  lastViewedCity?: City;
  setClosestCities: (cities: City[]) => void;
  closestCities?: City[];
}

export const CityContext = createContext<CityContextValues>({
  setLastViewedCity: () => {},
  setClosestCities: () => {},
});

const CityProvider: React.FC = ({ children }) => {
  const [lastViewedCity, setLastViewedCity] = useState<City | undefined>();
  const [closestCities, setClosestCities] = useState<
    CityContextValues['closestCities']
  >();

  const contextValue = useMemo(
    () => ({
      setLastViewedCity,
      lastViewedCity,
      setClosestCities,
      closestCities,
    }),
    [setLastViewedCity, lastViewedCity, setClosestCities, closestCities]
  );

  return (
    <CityContext.Provider value={contextValue}>{children}</CityContext.Provider>
  );
};

export const useCityContext: () => CityContextValues = () => {
  const value = useContext(CityContext);

  // if default value:
  if (value.setLastViewedCity == (() => {})) {
    throw new Error(
      'useCityContext must be called within a child or grandchild of a City Context Provider!'
    );
  }
  return value;
};

export default CityProvider;
